import React from "react";

export const ChasLogoBlack = (): React.ReactElement => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={48} height={24}>
      <g fill="#0C0C0C">
        <path d="M19.2 11.9V7.6h-4v4.3H12V.5h3.1v4.2h4V.5h3.1v11.2c0 .1 0 .2-.2.2h-2.8c.1 0 0 0 0 0zm-16.8 2h4c.1 0 .2 0 .3.1 3.1 2.9 6.7 4.9 10.8 5.9 1.2.3 2.4.5 3.6.6.9.1 1.8.1 2.7.1.9 0 1.7-.1 2.6-.2h.2c1.1.8 2.2 1.5 3.2 2.3 0 0 .1 0 .1.1-5.1 1.2-10.2 1.1-15.2-.6-5-1.5-9-4.4-12.3-8.3zm33.4-2h-3.2c-.1 0-.2 0-.2-.1-.2-.6-.5-1.2-.7-1.8 0-.1-.1-.1-.2-.1h-4.1c-.1 0-.2 0-.2.1-.2.6-.5 1.2-.7 1.8 0 .1-.1.1-.2.1h-3.1-.1v-.1c1.6-3.7 3.1-7.4 4.7-11.1 0-.1.1-.1.2-.1h2.8c.1 0 .2 0 .2.1 1.6 3.7 3.1 7.4 4.7 11.1.1 0 .1 0 .1.1zm-5.1-4.5c-.4-1.1-.8-2.1-1.3-3.2-.4 1.1-.9 2.2-1.3 3.2h2.6zm5.1 2.8c.6-.7 1.2-1.4 1.7-2.1.4.2.7.5 1.1.7.9.5 1.8.7 2.9.7.2 0 .5-.1.7-.2.5-.2.5-.9.1-1.2-.4-.2-.8-.3-1.2-.5-.7-.2-1.5-.4-2.2-.6-.5-.2-1-.4-1.4-.7-.6-.5-1-1.1-1-1.9-.1-.7 0-1.4.3-2 .4-.9 1.2-1.5 2.1-1.8.7-.2 1.4-.3 2.2-.3 1.3 0 2.5.3 3.7 1 .3.2.5.3.8.5-.5.8-1.1 1.5-1.6 2.2-.3-.2-.5-.3-.8-.5-.7-.4-1.5-.6-2.3-.6-.3 0-.5 0-.8.1-.3.1-.5.3-.5.5 0 .3.1.6.4.7.4.2.9.3 1.3.4.8.2 1.7.4 2.4.7.4.2.8.4 1.2.7.5.5.8 1.1.9 1.8.1.8-.1 1.5-.5 2.2-.5.8-1.2 1.3-2 1.6-.9.3-1.8.4-2.7.3-1-.1-2.1-.3-3-.8-.6-.3-1.1-.6-1.6-1-.1.2-.2.1-.2.1zM8.7 4.5L8 3.8c-1-.8-2.3-.9-3.3-.3-.7.5-1 1.1-1.2 1.9-.2.9-.1 1.7.4 2.5.6.9 1.6 1.4 2.7 1.2.9-.1 1.5-.6 2-1.2l.1-.1c.8.6 1.6 1.1 2.4 1.7-.2.3-.4.5-.7.8-.9.9-2 1.5-3.3 1.7-1.9.3-3.6-.1-5.1-1.5C1 9.6.4 8.5.2 7.2c-.2-1.6 0-3.1.9-4.4C2 1.4 3.3.6 4.9.3 6.2.1 7.4.2 8.6.7c.9.4 1.6 1 2.2 1.8v.1c-.6.7-1.4 1.3-2.1 1.9zm21.8 18.2c.1-.3.1-.5.1-.8.1-.8.3-1.6.4-2.5 0-.1.1-.2.2-.2 3.2-1.1 6.1-2.9 8.6-5.3.1-.1.2-.1.3-.1h4c-3.7 4.4-8.2 7.4-13.6 8.9z" />
        <path d="M47.8 4.2c0 .8-.6 1.5-1.4 1.4-.8 0-1.4-.7-1.4-1.4 0-.8.7-1.4 1.4-1.4.8-.1 1.4.6 1.4 1.4zm-.3 0c0-.6-.5-1.1-1.1-1.1-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1.6 0 1.1-.5 1.1-1.1z" />
        <path d="M45.8 3.5h.8c.2 0 .3.1.3.3 0 .2 0 .4-.2.5h-.1c.1.1.2.3.3.4h-.4s-.1 0-.1-.1c-.1-.1-.1-.2-.2-.2 0 0-.1-.1-.2-.1v.4h-.4c.2-.3.2-.7.2-1.2zm.4.3c0 .1 0 .3.1.3h.3v-.2c-.1-.1-.2 0-.4-.1z" />
      </g>
    </svg>
  );
};
