import React from "react";
import { Heading, Modal, Text } from "@clearabee/ui-library-native";
import { useTranslation } from "react-i18next";
import { styles } from "./AuthError.styles";
import { Button } from "components/Core/Button/Button";
import { useAuth } from "lib/hooks/useAuth";
import { Platform } from "react-native";
import { RefreshButton } from "./RefreshButton";

export const AuthError = (): React.ReactElement | null => {
  const { error, signOut } = useAuth();
  const [translate] = useTranslation();

  if (!error) {
    return null;
  }

  return (
    <Modal
      width={550}
      actions={[
        <RefreshButton />,
        <Button size="medium" onClick={signOut} color="accent">
          {translate("common.signOut")}
        </Button>,
      ]}
    >
      <Heading level={4} color="negative">
        {translate("hwa.components.authError.heading")}
      </Heading>

      <Text styles={[styles.text, styles.error]}>{error.message}</Text>

      <Text styles={styles.text}>
        {translate(
          Platform.OS === "web"
            ? "hwa.components.authError.messageWeb"
            : "hwa.components.authError.messageNative",
        )}
      </Text>
      <Text
        styles={[styles.text, styles.warning]}
        color="negative"
        fontSize="small"
      >
        {translate("hwa.components.authError.signOutWarning")}
      </Text>
    </Modal>
  );
};
