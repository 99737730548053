import { CognitoUser } from "@aws-amplify/auth";
import { captureException } from "@sentry/react";
import { ApiResponseData, instance } from "@clearabee/ui-sdk";

type Attribute = "bee_loyal_credit" | "bee_loyal_member" | "bee_loyal_end";

export type User = Omit<
  ApiResponseData<typeof instance.users.getUser>,
  "attributes"
> & {
  getAttribute: (attribute: Attribute) => string | boolean | number | undefined;
};

export const getAttribute =
  (
    user: ApiResponseData<typeof instance.users.getUser>,
  ): User["getAttribute"] =>
  (attribute: Attribute) => {
    const value = user.attributes?.find(
      ({ attrKey }) => attrKey === attribute,
    )?.attrValue;

    if (
      typeof value !== "undefined" &&
      typeof value !== "string" &&
      typeof value !== "number" &&
      typeof value !== "boolean"
    ) {
      const errorMessage = `Received an attribute which is not of a valid type. attribute: ${attribute}, data: ${JSON.stringify(
        user,
      )}`;

      captureException(new Error(errorMessage));
      console.error(errorMessage);
      return undefined;
    }

    return value;
  };

export const getEmail = (cognito: CognitoUser): Promise<string | undefined> => {
  return new Promise((resolve) => {
    cognito.getUserData((_, data) => {
      resolve(data?.UserAttributes.find(({ Name }) => Name === "email")?.Value);
    });
  });
};
