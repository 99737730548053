import React from "react";
import { Button as UIButton, ButtonProps } from "@clearabee/ui-library";

export const Button = React.forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  ButtonProps
>(({ children, ...props }, ref) => {
  return (
    <UIButton
      {...props}
      styles={{
        ...(props.styles as Record<string, unknown>),
        fontFamily: "Proxima",
      }}
      ref={ref}
    >
      {children}
    </UIButton>
  );
});
