import React from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { Amplify } from "aws-amplify";
import { instance, BasketProvider, ApiResponseData } from "@clearabee/ui-sdk";
import { ClearBasketOnSignOut } from "components/ClearBasketOnSignOut/ClearBasketOnSignOut";
import { captureException } from "@sentry/nextjs";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { AuthProvider } from "./authProvider";
import { DataProvider } from "./dataProvider";
import { SSRProvider } from "./ssrProvider";
import "lib/translations";
import { Platform } from "react-native";
import { getEnv } from "lib/getEnv";

Amplify.configure({
  region: getEnv("REGION"),
  userPoolId: getEnv("USER_POOL_ID"),
  userPoolWebClientId: getEnv("USER_POOL_WEB_CLIENT_ID"),
  cookieStorage:
    Platform.OS === "web" && typeof window !== "undefined"
      ? {
          domain: window.location.hostname,
          secure: window.location.protocol === "https:",
          path: "/",
          expires: 365,
        }
      : undefined,
});

dayjs.extend(utc);
dayjs.extend(advancedFormat);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

instance.axios.defaults.baseURL = getEnv("API_BASE_URL");
instance.axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error)) {
      captureException(error, {
        extra: {
          request: error.request,
          response: error.response,
        },
      });
    } else {
      captureException(error);
    }
    return Promise.reject(error);
  },
);

interface AppProvidersProps {
  children: React.ReactElement;
  serverBasket?:
    | ApiResponseData<typeof instance.catalogues.getBasket>
    | undefined;
  user?: ApiResponseData<typeof instance.users.getUser>;
}

export const AppProviders = ({
  children,
  user,
  serverBasket,
}: AppProvidersProps): React.ReactElement => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider user={user}>
        <BasketProvider catalogueId="hwa" basket={serverBasket}>
          <DataProvider>
            <SSRProvider>
              <ClearBasketOnSignOut>{children}</ClearBasketOnSignOut>
            </SSRProvider>
          </DataProvider>
        </BasketProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};
