import React, { useState } from "react";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { Heading, Modal, Text } from "@clearabee/ui-library-native";
import { useBasket } from "@clearabee/ui-sdk";
import { useTranslation } from "react-i18next";
import { styles } from "./ExpiredBasket.styles";
import { Button } from "components/Core/Button/Button";
import { useData } from "lib/hooks/useData";

export const ExpiredBasket = (): React.ReactElement | null => {
  const { basket, reset, fetch } = useBasket();
  const [isOpen, setOpen] = useState(false);
  const [translate] = useTranslation();
  const [{ serverDate }] = useData();

  const handleClick = () => {
    reset();
    setOpen(false);
  };

  useQuery(
    ["refetchBasket", fetch.refetch, basket?.basketToken],
    fetch.refetch,
    {
      staleTime: 300000, // 5 minutes
      refetchOnWindowFocus: true,
      enabled: !!basket,
    },
  );

  useQuery(
    ["checkBasketIsExpired", basket, serverDate, fetch.dataUpdatedAt],
    () => {
      if (basket && dayjs(basket.expiryDate).diff(serverDate) <= 0) {
        if (basket.items.length) {
          setOpen(true);
        } else {
          reset();
        }
      }
    },
  );

  if (!isOpen) {
    return null;
  }

  return (
    <Modal width={550}>
      <Heading level={4} color="negative">
        {translate("hwa.components.expiredBasket.heading")}
      </Heading>

      <Text styles={styles.text}>
        {translate("hwa.components.expiredBasket.message")}
      </Text>

      <Button size="medium" onClick={handleClick}>
        {translate("hwa.components.expiredBasket.button")}
      </Button>
    </Modal>
  );
};
