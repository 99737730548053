import type config from "../../app.config";
import Constants from "expo-constants";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

export const getEnv = <T extends keyof typeof config.expo.extra>(
  key: T,
): typeof config.expo.extra[T] => {
  return (
    Constants.expoConfig?.extra?.[key] ||
    Constants.manifest?.extra?.[key] ||
    publicRuntimeConfig.env[key] ||
    process.env[key] ||
    ""
  );
};
