import React from "react";
import dayjs from "dayjs";
import { styles } from "./styles";

export const CompanyDetails = (): React.ReactElement => {
  return (
    <div css={styles.companyDetails}>
      <div>
        &copy; Clearabee {dayjs().format("YYYY")}. All rights reserved.{" "}
        <br css={styles.conditionalLine} />
        Clearabee is a trading name of Clearabee Limited.
      </div>
      <div>
        <span style={styles.textBold}>Company Reg:</span> 07938514{" "}
        <span style={styles.textBold}>VAT Reg:</span> 141 0827 45{" "}
        <br css={styles.conditionalLine} />
        <span style={styles.textBold}>Waste Carrier Licence:</span> CBDU61392
      </div>
    </div>
  );
};
