import React, { useEffect, useRef } from "react";
import { useBasket } from "@clearabee/ui-sdk";
import { useAuth } from "lib/hooks/useAuth";

interface ClearBasketOnSignOutProps {
  children: React.ReactNode;
}

export const ClearBasketOnSignOut = ({
  children,
}: ClearBasketOnSignOutProps): React.ReactElement => {
  const { reset } = useBasket();
  const { user } = useAuth();
  const userRef = useRef(user);

  useEffect(() => {
    // on user sign out
    if (!user && userRef.current) {
      reset();
    }

    userRef.current = user;
  }, [user]);

  return <>{children}</>;
};
