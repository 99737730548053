import React from "react";
import getConfig from "next/config";
import { useRouter } from "next/router";
import Head from "next/head";

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  cannonicalUrl?: string;
  type?: string;
}

const { publicRuntimeConfig: config } = getConfig() ?? {};
const { domain } = config?.env ?? {};

export const SEO = ({
  cannonicalUrl,
  description = "Same day rubbish removal and skip bag service by Clearabee. We’re the UK’s largest in-house operator. We’re open 7 days a week.",
  image = `https://${domain}/images/open-graph/placeholder.png`,
  type = "website",
  title = "Rubbish Removal",
}: SEOProps): React.ReactElement => {
  const { asPath } = useRouter();

  return (
    <Head>
      <title>{title} - Clearabee®</title>
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />

      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />

      <meta property="og:image" content={image} />
      <meta property="twitter:image" content={image} />
      <meta name="og:image:alt" content={title} />
      <meta name="twitter:image:alt" content={title} />

      <meta property="og:site_name" content="Home Waste App - Clearabee" />

      <meta property="type" content={type} />
      <meta property="og:type" content={type} />

      <meta name="twitter:site" content="@clearabee" />
      <meta name="twitter:card" content="summary_large_image" />

      <link
        rel="canonical"
        href={cannonicalUrl || `https://${domain}${asPath}`}
      />
      <meta name="robots" content="follow, index" />
      <meta name="googlebot" content="index,follow" />
    </Head>
  );
};
