import React, { createContext, useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import { AuthClass } from "@aws-amplify/auth/lib-esm/Auth";
import { Platform } from "react-native";
import { instance, ApiResponseData, ApiRequestData } from "@clearabee/ui-sdk";
import { useTranslation } from "react-i18next";
import { getAttribute, getEmail, User } from "lib/auth";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { useQuery } from "react-query";
import Cookies from "js-cookie";
import { getEnv } from "lib/getEnv";

export interface AuthContextState {
  user: User | null;
  cognito: CognitoUser | null;
  signIn: (username: string, password: string) => Promise<User | null>;
  resendSignUpLink: (email: string) => Promise<void>;
  signUp: (
    body: Pick<
      ApiRequestData<typeof instance.users.postUser>,
      "email" | "password" | "firstName" | "lastName"
    >,
  ) => Promise<ApiResponseData<typeof instance.users.postUser>>;
  signOut: () => Promise<void>;
  forgotPassword: (username: string) => Promise<void>;
  updateUser: (
    body: ApiRequestData<typeof instance.users.patchUser>,
  ) => Promise<ApiResponseData<typeof instance.users.patchUser>>;
  refetchUserData: () => void;
  error: Error | undefined;
  Auth: AuthClass;
  isLoading: boolean;
}

interface AuthProviderProps {
  children: React.ReactNode;
  user?: ApiResponseData<typeof instance.users.getUser>;
}

export class AuthError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "AuthException";
  }
}

export const AuthContext = createContext<AuthContextState>({
  user: null,
} as AuthContextState);

const addInterceptorToUser = (cognito: CognitoUser) =>
  instance.axios.interceptors.request.use(
    async (config) => {
      return new Promise((resolve, reject) => {
        cognito?.getSession(
          (error: Error | undefined, session: CognitoUserSession | null) => {
            if (error || !session) {
              return reject(error);
            }
            config.headers = config.headers || {};
            config.headers.Authorization = `Bearer ${session
              .getIdToken()
              .getJwtToken()}`;

            resolve(config);
          },
        );
      });
    },
    (error) => Promise.reject(error),
  );

export const AuthProvider = ({
  children,
  user: initialUser,
}: AuthProviderProps): React.ReactElement => {
  const [translate] = useTranslation();
  const [error, setError] = useState<Error>();
  const [user, setUser] = useState<User | null>(
    initialUser
      ? { ...initialUser, getAttribute: getAttribute(initialUser) }
      : null,
  );
  const [cognito, setCognito] = useState<CognitoUser | null>(null);
  const interceptorRef = useRef<number | null>(null);

  const { isFetching, refetch: refetchUserData } = useQuery(
    ["refreshData", user, cognito],
    async () => {
      const email = user?.email ?? (await tryToFetchData(cognito))?.email;

      if (email) {
        console.log("Refreshing user data...");
        return fetchUserData(email);
      }
    },
    {
      onSuccess: (data) => {
        if (data) setUser(data);
      },
      initialData: initialUser
        ? { ...initialUser, getAttribute: getAttribute(initialUser) }
        : null,
      staleTime: 10 * 1000, // prevents initial mount fetch
      enabled: !!cognito,
      retry: 1,
      refetchOnWindowFocus: "always",
    },
  );

  const fetchUserData = async (email: string) => {
    const user = (await instance.users.getUser(email)).data as User;

    user.getAttribute = getAttribute(user);
    return user;
  };

  const tryToFetchData = async (cognito: CognitoUser | null) => {
    return new Promise<User | null>(async (resolve, reject) => {
      if (!cognito) return resolve(null);

      let email: string | undefined = undefined;

      try {
        email = await getEmail(cognito);
      } catch (error) {
        reject(new AuthError((error as Error).message));
      }

      if (!email) {
        return reject(new AuthError(translate("hwa.errors.auth.awsEmail")));
      }

      try {
        const user = await fetchUserData(email);
        resolve(user);
      } catch (error) {
        reject(new AuthError((error as Error).message));
      }
    });
  };

  const resendSignUpLink = async (email: string): Promise<void> => {
    await Auth.resendSignUp(email);
  };

  const signIn: AuthContextState["signIn"] = async (email, password) => {
    const cognito: CognitoUser = await Auth.signIn(email, password);
    setCognito(cognito);

    try {
      interceptorRef.current = addInterceptorToUser(cognito);
      const user = await tryToFetchData(cognito);
      setUser(user);
    } catch (error) {
      signOut();
      throw error;
    }

    return user;
  };

  const signUp: AuthContextState["signUp"] = async (body) => {
    return (await instance.users.postUser(body)).data;
  };

  const signOut: AuthContextState["signOut"] = async () => {
    setUser(null);
    setError(undefined);
    delete instance.axios.defaults.headers.common.Authorization;
    await Auth.signOut();
    if (interceptorRef.current !== null) {
      instance.axios.interceptors.request.eject(interceptorRef.current);
      interceptorRef.current = null;
    }
    setCognito(null);
    for (const key of Object.keys(Cookies.get())) {
      key.includes("CognitoIdentityServiceProvider") && Cookies.remove(key);
    }
  };

  const forgotPassword: AuthContextState["forgotPassword"] = async (email) => {
    return Auth.forgotPassword(email, {
      originUrl:
        Platform.OS === "web"
          ? window.location.origin
          : getEnv("WEBSITE_URL") ?? "",
    });
  };

  const updateUser: AuthContextState["updateUser"] = async (body) => {
    if (!user) {
      throw new Error("Cannot patch a guest user");
    }

    const data = (await instance.users.patchUser(user.email, body)).data;

    setUser({ ...data, getAttribute: getAttribute(data) });

    return data;
  };

  useEffect(() => {
    (async () => {
      let cognito: CognitoUser | null = null;

      try {
        cognito = await Auth.currentAuthenticatedUser();
        cognito && (interceptorRef.current = addInterceptorToUser(cognito));
      } catch {
        signOut();
      }

      setCognito(cognito);
    })();
  }, []);

  // Used to fetch the initial data on Native
  useEffect(() => {
    if (cognito && !user) refetchUserData();
  }, [cognito]);

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        signUp,
        resendSignUpLink,
        signOut,
        updateUser,
        forgotPassword,
        refetchUserData,
        Auth,
        error,
        cognito,
        isLoading: isFetching,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
