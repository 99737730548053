import React from "react";
import { Button } from "components/Core/Button/Button";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";

export const RefreshButton = (): React.ReactElement => {
  const [translate] = useTranslation();
  const router = useRouter();

  return (
    <Button size="medium" onClick={router.reload} color="brand">
      {translate("hwa.components.authError.refreshButton")}
    </Button>
  );
};
