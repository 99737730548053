"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.portal = void 0;
const reporting_1 = require("./reporting");
const portal_1 = require("./portal");
const app_1 = require("./app");
const quotes_1 = require("./quotes");
const auth_1 = require("./auth");
const leaderboard_1 = require("./leaderboard");
const dashboard_1 = require("./dashboard");
const common_1 = require("./common");
const users_1 = require("./users");
const jobs_1 = require("./jobs");
const companies_1 = require("./companies");
const catalogues_1 = require("./catalogues");
const suppliers_1 = require("./suppliers");
const worksheets_1 = require("./worksheets");
const permissions_1 = require("./permissions");
const roles_1 = require("./roles");
const drivers_1 = require("./drivers");
const statistics_1 = require("./statistics");
exports.portal = {
    reporting: reporting_1.reporting,
    portal: portal_1.portal,
    app: app_1.app,
    quotes: quotes_1.quotes,
    auth: auth_1.auth,
    leaderboard: leaderboard_1.leaderboard,
    dashboard: dashboard_1.dashboard,
    common: common_1.common,
    users: users_1.users,
    jobs: jobs_1.jobs,
    drivers: drivers_1.drivers,
    companies: companies_1.companies,
    catalogues: catalogues_1.catalogues,
    suppliers: suppliers_1.suppliers,
    worksheets: worksheets_1.worksheets,
    permissions: permissions_1.permissions,
    roles: roles_1.roles,
    statistics: statistics_1.statistics,
};
