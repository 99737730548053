import React from "react";
import { css } from "@emotion/react";
import {
  ColorKeyVariants,
  getColorFromVariantKey,
} from "@clearabee/ui-library-base";
import { UserStylesProps } from "../../utils";

export type ListItemProps = UserStylesProps<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLLIElement>, HTMLLIElement>
>;

type UListDetailedProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLUListElement>,
  HTMLUListElement
>;

type OListDetailedProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLOListElement>,
  HTMLOListElement
>;

type ListDetailedProps = UListDetailedProps & OListDetailedProps;

export interface ListProps extends UserStylesProps<ListDetailedProps> {
  as?: "ul" | "ol";
  color?: ColorKeyVariants;
}

const ListItem = ({
  styles: userStyles,
  ...rest
}: ListItemProps): React.ReactElement => {
  return <li css={css(userStyles)} {...rest} />;
};

export const List = ({
  as = "ul",
  styles: userStyles,
  color,
  ...rest
}: ListProps): React.ReactElement => {
  const Component = as;
  return (
    <Component
      css={css(userStyles, color && { color: getColorFromVariantKey(color) })}
      {...rest}
    />
  );
};

List.Item = ListItem;
