import React, { createContext, useReducer } from "react";
import { ToOptional } from "lib/types";
import { useEffect } from "react";
import { Platform } from "react-native";

export type DataTypes = ToOptional<typeof dataTypes>;
export type DataProviderValue = [
  DataTypes,
  <Key extends keyof DataTypes>(
    key: Key,
    value: DataTypes[Key],
    persist?: boolean,
  ) => void,
];

export interface DataProviderProps {
  children: React.ReactNode;
  initialValues?: DataTypes;
}

const dataTypes = {
  orderRef: undefined as string | undefined,
  categorySku: undefined as string | undefined,
  serverDate: new Date(),
};

export const DataContext = createContext<DataProviderValue | undefined>(
  undefined,
);

const dataReducer = (
  prevState: DataTypes,
  nextState: Partial<DataTypes>,
): DataTypes => ({
  ...prevState,
  ...nextState,
});

export const DataProvider = ({
  children,
  initialValues,
}: DataProviderProps): React.ReactElement => {
  const [data, setData] = useReducer(dataReducer, initialValues ?? dataTypes);

  useEffect(() => {
    if (Platform.OS === "web") {
      const persistedData = Object.keys(dataTypes).reduce((curr, key) => {
        try {
          const local = window.localStorage.getItem(key);
          if (!local) return curr;
          return {
            ...curr,
            [key]: JSON.parse(local),
          };
        } catch {
          window.localStorage.removeItem(key);
          return curr;
        }
      }, {});
      if (!!Object.keys(persistedData).length) setData(persistedData);
    }
  }, []);

  return (
    <DataContext.Provider
      value={[
        data,
        (key, value, persist) => {
          setData({
            [key]: value,
          });
          if (
            Platform.OS === "web" &&
            persist &&
            typeof window !== "undefined"
          ) {
            if (!!value) {
              window.localStorage.setItem(key, JSON.stringify(value));
            } else {
              window.localStorage.removeItem(key);
            }
          }
        },
      ]}
    >
      {children}
    </DataContext.Provider>
  );
};
