import { useContext } from "react";
import { DataProviderValue, DataContext } from "lib/providers/dataProvider";

export const useData = (): DataProviderValue => {
  const context = useContext(DataContext);

  if (!context) {
    throw new Error("Cannot use Data outside of its provider");
  }

  return context;
};
