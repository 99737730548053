"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.portalNext = void 0;
const nav_1 = require("./nav");
const filterBar_1 = require("./filterBar");
const tablePanel_1 = require("./tablePanel");
const searchPanel_1 = require("./searchPanel");
const offTheRoadPanel_1 = require("./offTheRoadPanel");
const filterSet_1 = require("./filterSet");
const vehicleDetails_1 = require("./vehicleDetails");
const mapView_1 = require("./mapView");
const viewBar_1 = require("./viewBar");
const api_1 = require("./api");
const contactDriver_1 = require("./contactDriver");
const settings_1 = require("./settings");
const jobPanel_1 = require("./jobPanel");
const journeyDetails_1 = require("./journeyDetails");
const driversJobs_1 = require("./driversJobs");
const healthCheck_1 = require("./healthCheck");
const dashboard_1 = require("./dashboard");
exports.portalNext = {
    nav: nav_1.nav,
    filterBar: filterBar_1.filterBar,
    filterSet: filterSet_1.filterSet,
    tablePanel: tablePanel_1.tablePanel,
    searchPanel: searchPanel_1.searchPanel,
    offTheRoadPanel: offTheRoadPanel_1.offTheRoadPanel,
    vehicleDetails: vehicleDetails_1.vehicleDetails,
    mapView: mapView_1.mapView,
    viewBar: viewBar_1.viewBar,
    journey: api_1.journey,
    journeyDetails: journeyDetails_1.journeyDetails,
    contactDriver: contactDriver_1.contactDriver,
    settings: settings_1.settings,
    jobPanel: jobPanel_1.jobPanel,
    driversJobs: driversJobs_1.driversJobs,
    healthCheck: healthCheck_1.healthCheck,
    dashboard: dashboard_1.dashboard,
};
