"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.drivers = void 0;
exports.drivers = {
    jobs: {
        headings: {
            read: "Driver Jobs",
        },
        form: {
            headings: {
                driver: "Select a driver",
                driverEmail: "Email",
                totalJobs: "Total Jobs",
                vehicleReg: "Registration",
                jobRef: "Reference",
                jobDate: "Date",
                jobPostcode: "Postcode",
                jobBcId: "BC Id",
                jobStatus: "Status",
                jobStatusId: "Status Id",
                jobVehicleId: "Vehicle Id",
                jobAsset: "Asset",
                jobType: "Type",
                jobOrderTypeId: "Order Type Id",
            },
        },
        buttons: {
            reset: "Reset",
        },
        errors: {
            notFoundDriver: "The selected user isn't a driver",
            noJobsFound: "There are no jobs associated with this driver today, please select another driver",
        },
    },
    notifications: {
        headings: {
            readNotification: "Driver Notifications",
        },
        form: {
            headings: {
                driver: "Driver*",
                notificationTitle: "Title",
                notificationBody: "Body",
                notificationJSON: "JSON Data*",
                allocatedDrivers: "Allocated Drivers",
            },
            placeholders: {
                driver: "Select a driver",
                notificationTitle: "Enter Title",
                notificationBody: "Enter Body",
                notificationJSON: {
                    key: "Enter Key",
                    value: "Enter Value",
                },
            },
        },
        table: {
            notificationJSON: {
                headings: {
                    key: "Key",
                    value: "Value",
                },
            },
            actions: {
                action: "Action",
                add: "Add",
                delete: "Delete",
            },
        },
        buttons: {
            add: "Add",
            reset: "Reset",
            notification: "Send Notification",
        },
        errors: {
            noDriverSelected: "Please select at least one driver",
            driversNotification: "{{error}}, please click reset and try again",
        },
        success: {
            sentNotificationDrivers: "Successfully sent notifications to selected drivers",
        },
        textSelectDropDown: "Listed drivers only include those with a device token",
    },
    create: {
        headings: {
            create: "Create Driver",
        },
        form: {
            headings: {
                driverEmail: "Email",
                driverFirstName: "First Name",
                driverLastName: "Last Name",
                driverPhoneNumber: "Phone Number",
                driverTemporaryPassword: "Temporary Password",
            },
            placeholders: {
                driverEmail: "Enter Email",
                driverFirstName: "Enter First Name",
                driverLastName: "Enter Last Name",
                driverPhoneNumber: "Enter Phone Number",
                driverTemporaryPassword: "Enter Temporary Password",
            },
        },
        buttons: {
            reset: "Reset",
            submit: "Submit",
        },
        links: {
            allocate: "Allocate Drivers",
        },
        errors: {
            createDriver: "Cannot create new driver account, please click reset and try again",
        },
        success: {
            successCreatedNewDriver: "Successfully created new driver account",
        },
    },
    allocate: {
        headings: {
            driverAllocation: "Driver Allocation",
            allocatedDrivers: "Allocated Drivers",
        },
        form: {
            headings: {
                driver: "Driver",
                vehicle: "Vehicle",
            },
            placeholders: {
                driver: "Select a driver",
                vehicle: "Select a vehicle",
            },
        },
        table: {
            actions: {
                add: "Add",
                delete: "Delete",
            },
        },
        buttons: {
            reset: "Reset",
            update: "Update",
            allocate: "Allocate Drivers",
        },
        errors: {
            driversAllocation: "{{error}}, please click reset and try again",
        },
        success: {
            successAllocatedDrivers: "Successfully updated new drivers to selected vehicle",
        },
        existingDrivers: "Existing Drivers:",
    },
};
