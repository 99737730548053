import React from "react";
import { styles } from "../styles";
import { BscLogoBlack } from "./BscLogoBlack";
import { ChasLogoBlack } from "./ChasLogoBlack";
import { SafeContractorApprovedBlack } from "./SafeContractorApprovedBlack";

const accreditations = [
  ChasLogoBlack,
  SafeContractorApprovedBlack,
  BscLogoBlack,
];

export const Accreditations = (): React.ReactElement => {
  return (
    <ul style={styles.accreditations}>
      {accreditations.map((Component, index) => (
        <li style={styles.accreditation} key={index}>
          <Component />
        </li>
      ))}
    </ul>
  );
};
