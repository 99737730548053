import React, { useState, useEffect, createContext, useRef } from "react";
import { styles } from "./Header.styles";
import { Link } from "../../Core/Link/Link";
import { Icon } from "../../Core/Icon/Icon";
import { CapitalizeProperty, LinkWrap } from "../../utils/types";
import { BasketPopup } from "./components/BasketPopup/BasketPopup";
import { useResponsive } from "../../Hooks/useResponsive";
import { IBasket } from "@clearabee/api-schemas";

export interface HeaderBasketItem {
  sku: string;
  img?: string | null;
  title?: string;
  lineCost?: number;
  link?: string;
  isRemovable?: boolean;
}

export interface HeaderBasket
  extends Pick<IBasket, "totalCost" | "meta" | "deliveryAddress"> {
  items: HeaderBasketItem[];
  onRemoveBasketItem: (sku: string) => void;
  onClearBasket: () => void;
  isUpdating: boolean;
  isError: boolean;
}

export interface HeaderProps {
  linkWrap: LinkWrap;
  basket: HeaderBasket;
  isLoggedIn: boolean;
}

interface BasketLinkProps {
  children: React.ReactNode;
}

type ContextState = CapitalizeProperty<HeaderProps, "linkWrap">;

export const HeaderContext = createContext<ContextState>({} as ContextState);

export const Header = ({
  linkWrap: LinkWrap,
  basket,
  isLoggedIn,
}: HeaderProps): React.ReactElement => {
  const isMounted = useRef(false);
  const [basketPopup, setBasketPopup] = useState(false);
  const { screens } = useResponsive();
  const [isScrolling, setScrolling] = useState(false);
  const overlayRef = useRef<HTMLDivElement>(null);

  const resetPopups = () => {
    setBasketPopup(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 45);
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => resetPopups(), [screens.medium]);

  // A fix for server/client HTML mismatch warning
  useEffect(() => {
    isMounted.current = true;
  }, []);

  const BasketLink = ({ children }: BasketLinkProps) => {
    return isMounted.current && screens.medium ? (
      <div
        onClick={() => setBasketPopup(!basketPopup)}
        data-testid="popup-link"
      >
        {children}
      </div>
    ) : (
      <LinkWrap link="/basket">
        <Link>{children}</Link>
      </LinkWrap>
    );
  };

  return (
    <HeaderContext.Provider
      value={{
        LinkWrap: (props) => (
          <div onClick={resetPopups}>
            <LinkWrap {...props} />
          </div>
        ),
        basket,
        isLoggedIn,
      }}
    >
      <div css={basketPopup && styles.overlay} />
      <div
        css={[
          styles.baseContainer,
          (isScrolling || basketPopup) && styles.headerBackground,
        ]}
        ref={overlayRef}
      >
        <div style={styles.headerItems}>
          <div css={[styles.logo, !isScrolling && styles.logoScrolling]}>
            <LinkWrap link="/">
              <Link>
                <Icon.Logo
                  color={
                    isMounted.current &&
                    (isScrolling || basketPopup || !screens.medium)
                      ? "light"
                      : "dark"
                  }
                />
              </Link>
            </LinkWrap>
          </div>
          <div style={styles.navigationLinks}>
            <div css={styles.textLinks}>
              <LinkWrap link="/book">
                <Link color="light" styles={styles.text}>
                  Book
                </Link>
              </LinkWrap>
              <LinkWrap link="/waste-calculator">
                <Link color="light" styles={styles.text}>
                  Waste Calculator
                </Link>
              </LinkWrap>
            </div>

            <div style={styles.linkSpace}>
              <BasketLink>
                <>
                  <Icon.Basket
                    size="medium"
                    styles={[
                      styles.iconLink,
                      basketPopup && styles.iconLink[":hover"],
                    ]}
                  />
                  <div style={styles.basketCountContainer}>
                    {isMounted.current && !!basket.items.length && (
                      <span style={styles.basketCount}>
                        {/* Can only have one service in basket at a time until ms-baskets api */}
                        1
                      </span>
                    )}
                  </div>
                </>
              </BasketLink>
            </div>

            <div style={styles.linkSpace}>
              <LinkWrap link={isLoggedIn ? "/account" : "/login"}>
                <Link>
                  <Icon.Account size="medium" styles={styles.iconLink} />
                </Link>
              </LinkWrap>
            </div>
          </div>
          {basketPopup && (
            <BasketPopup
              overlayRef={overlayRef}
              onClose={() => setBasketPopup(false)}
            />
          )}
        </div>
      </div>
    </HeaderContext.Provider>
  );
};
