import React, { createContext, useEffect, useState } from "react";

type SSRContextState = boolean;

export const SSRContext = createContext<SSRContextState>(true);

export const SSRProvider = ({
  children,
}: {
  children?: React.ReactNode;
}): React.ReactElement => {
  const [isSSR, setIsSSR] = useState(true);
  /**
   * useEffect is only ever ran client side
   */
  useEffect(() => {
    setIsSSR(false);
  }, []);

  return <SSRContext.Provider value={isSSR}>{children}</SSRContext.Provider>;
};
