import { StyleSheet } from "react-native";
import { theme } from "@clearabee/ui-library-native";

export const styles = StyleSheet.create({
  text: {
    marginTop: theme.spacing.xsmall,
    marginBottom: theme.spacing.small,
    fontWeight: "600",
    textAlign: "center",
  },
});
