import React from "react";
import { styles } from "./styles";
import { Link } from "../../../Core/Link/Link";
import { useLinkContext } from "../LinkContext";

interface Link {
  title: string;
  link: string;
}

const links: Link[] = [
  {
    title: "Terms & Conditions",
    link: "/terms",
  },
  {
    title: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    title: "Cookies",
    link: "/cookie-policy",
  },
];

export const SecondaryLinks = (): React.ReactElement => {
  const { LinkWrap } = useLinkContext();

  return (
    <ul style={styles.secondaryLinksContainer}>
      {links.map((item, index) => (
        <li key={item.title} style={styles.secondaryLink}>
          <LinkWrap link={item.link}>
            <Link color="dark" styles={styles.footerLink}>
              {item.title}
            </Link>
          </LinkWrap>
          {index + 1 < links.length && (
            <div style={styles.secondaryLinksSeparator} />
          )}
        </li>
      ))}
    </ul>
  );
};
