import { StyleSheet } from "react-native";
import { theme } from "@clearabee/ui-library-native";

export const styles = StyleSheet.create({
  text: {
    fontWeight: "600",
    textAlign: "center",
  },
  error: {
    marginTop: theme.spacing.xsmall,
    marginBottom: theme.spacing.medium,
  },
  warning: {
    marginTop: theme.spacing.xsmall2,
    marginBottom: theme.spacing.small,
    fontWeight: "400",
    textAlign: "center",
  },
});
