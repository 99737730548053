import React, { createContext, useContext } from "react";
import { LinkWrap } from "../../utils/types";

export interface LinkProps {
  children: React.ReactNode;
  component: LinkWrap;
}

interface LinkContextState {
  LinkWrap: LinkWrap;
}

const LinkContext = createContext<LinkContextState | undefined>(undefined);

export const useLinkContext = (): LinkContextState => {
  const context = useContext(LinkContext);

  if (!context) {
    throw new Error("Cannot use LinkContext outside of its provider");
  }

  return context;
};

export const LinkProvider = ({
  children,
  component,
}: LinkProps): React.ReactElement => {
  return (
    <LinkContext.Provider
      value={{
        LinkWrap: component,
      }}
    >
      {children}
    </LinkContext.Provider>
  );
};
