import React from "react";
import { css } from "@emotion/react";
import { UserStylesProps } from "../../utils";
import { Text } from "../../Core/Text/Text";
import { styles } from "./Pricing.styles";

export interface PricingProps
  extends UserStylesProps<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
  > {
  align?: "left" | "center" | "right" | "inline";
  prepend?: React.ReactNode;
  append?: React.ReactNode;
}

export const Pricing = ({
  align = "left",
  prepend = "From",
  append,
  children,
  styles: userStyles,
  ...rest
}: PricingProps): React.ReactElement => {
  return (
    <div
      role="note"
      css={css(styles.defaults, align && styles[align], userStyles)}
      data-testid="pricing-block"
      {...rest}
    >
      {typeof prepend === "string" ? <Text as="span">{prepend}</Text> : prepend}
      <Text as="span" fontSize="xlarge3" styles={styles.price}>
        {children}
      </Text>

      {typeof append === "string" ? (
        <Text as="span" fontSize="small">
          {append}
        </Text>
      ) : (
        append
      )}
    </div>
  );
};
