"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.worksheets = void 0;
exports.worksheets = {
    headings: {
        read: "Read Worksheets",
        create: "Create Worksheet",
        update: "Update Worksheet",
        arrange: "Arrange Worksheets",
        questions: "Worksheet Questions",
    },
    arrange: {
        noData: "There are no worksheets associated with this id",
        instruction: "Arrange worksheets by dragging them up or down",
        success: "Worksheets updated successfully",
        error: "Something went wrong, please try again",
        errorReadTypeIds: "Cannot read order type ids",
        searchButton: "Search",
        updateButton: "Update",
    },
    readWorksheets: {
        arrangeWorksheets: "Arrange Worksheets",
        add: "Add",
        headings: {
            id: "ID",
            name: "Name",
            priority: "Priority",
            includeForSnsEvents: "SNS Events",
        },
        getError: "Worksheets list could not be fetched. Try again",
    },
    createWorksheets: {
        cancel: "Cancel",
        create: "Create",
        add: "Add",
        addNewQuestion: "Add New Question",
        update: "Update",
        reset: "Reset",
        edit: "Edit",
        delete: "Delete",
        headings: {
            id: "Question ID",
            questions: "Create Questions",
            question: "Question Text",
            type: "Type",
            position: "Position",
            statusId: "Status ID",
            typeValues: "Type Values",
            questionBcQuestionIds: "Big Change ID",
            parentId: "Parent Question ID",
            displayCondition: "Display Condition",
            meta: "Meta",
            key: "Key",
            value: "Value",
        },
        placeholders: {
            name: "Name",
            orderTypeIds: "Order Type IDs",
            includeForSnsEvents: "SNS Events",
            priority: "Priority",
            statusId: "Status ID",
            questions: "Questions",
            questionName: "Question Text",
            questionType: "Type",
            questionTypeValues: "Type Values",
            questionPosition: "Position",
            questionStatusId: "Status ID",
            questionBcQuestionIds: "Big Change ID",
            questionParentId: "Parent ID",
            questionDisplayCondition: "Condition",
            questionMeta: "{{prefix}} Meta",
            questionMetaKey: "Key",
            questionMetaValue: "Value",
        },
        postError: "Worksheet could not be created. Try again",
        fieldRequired: "There are fields with outstanding errors below",
        questionFieldRequired: "All required fields should be filled",
        postSuccess: "Worksheet added successfully",
    },
    orderTypeValue: {
        add: "Add",
        update: "Update",
        cancel: "Cancel",
        addNewOrderTypeValue: "Add New Order Type Value",
        editOrderTypeValue: "Edit Order Type Value",
        headings: {
            id: "ID",
            value: "Value",
        },
        placeholders: {
            value: "Value",
        },
        getError: "Order Type Value list could not be fetched. Try again",
        postError: "Order Type Value could not be created. Try again",
        postSuccess: "Order Type Value added successfully",
        patchError: "Order Type Value could not be updated. Try again",
        patchSuccess: "Order Type Value updated",
    },
    table: {
        headings: {
            action: "Action",
        },
        actions: {
            edit: "Edit",
        },
    },
    modal: {
        removal: "Do you want to delete this question?",
        confirm: "Confirm",
        cancel: "Cancel",
    },
    noQuestionText: "New question has not been completed yet",
    expandAllQuestions: "Expand All",
};
