import React, { useEffect } from "react";
import { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import { AppProviders } from "lib/providers/appProviders";
import { SEO } from "components/Seo/Seo";
import { useRouter } from "next/router";
import { ExpiredBasket } from "components/ExpiredBasket/ExpiredBasket";
import { AuthError } from "components/AuthError/AuthError";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/nextjs";
import { PortalProvider } from "@gorhom/portal";
import "lib/sentry";
import "lib/styles/fonts.css";
import { getEnv } from "lib/getEnv";
import { useBasket } from "@clearabee/ui-sdk";
import Cookies from "js-cookie";

const gtmId = getEnv("GTM_ID");

const BasketRefCookieStore = (): null => {
  const { basket } = useBasket();

  useEffect(() => {
    try {
      const cookieToken = Cookies.get("basketToken");
      if (!basket && cookieToken) {
        Cookies.remove("basketToken");
      } else if (basket && cookieToken !== basket.basketToken) {
        Cookies.set("basketToken", basket?.basketToken);
      }
    } catch (error) {
      console.error(error);
    }
  }, [basket]);

  return null;
};

const App = ({ Component, pageProps }: AppProps): React.ReactElement => {
  const { asPath } = useRouter();

  if (process.env.NODE_ENV !== "development") {
    useEffect(() => {
      if (
        "serviceWorker" in navigator &&
        getEnv("WEBSITE_URL") === window.location.origin
      ) {
        const paths = asPath.split("?")[0].split("/").find(Boolean);
        const scope = `/${paths}`;

        navigator.serviceWorker.register("/hwa-proxy.js", {
          scope,
        });
      }
    }, [asPath]);
  }

  return (
    <SentryErrorBoundary>
      {gtmId && (
        <Script
          id="tag-manager"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmId}');
          `,
          }}
        />
      )}
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link
          rel="preconnect"
          href="https://use.typekit.net"
          crossOrigin="anonymous"
        />
        <link
          rel="preconnect"
          href="https://p.typekit.net"
          crossOrigin="anonymous"
        />
        <link
          rel="preconnect"
          href="https://www.googletagmanager.com"
          crossOrigin="anonymous"
        />
      </Head>
      <SEO />

      <AppProviders user={pageProps.user}>
        <PortalProvider>
          <Component {...pageProps} />
          <ExpiredBasket />
          <AuthError />
          <BasketRefCookieStore />
        </PortalProvider>
      </AppProviders>
    </SentryErrorBoundary>
  );
};

export default App;
