import React from "react";
import { css } from "@emotion/react";
import { theme } from "../../theme";
import { styles } from "./Pagination.styles";
import { Icon } from "../../Core/Icon/Icon";
import { Text } from "../../Core/Text/Text";
import { InputSelect } from "../../Core/Form/Inputs/InputSelect/InputSelect";

export interface PaginationState {
  currentPage: number;
  totalPages: number;
  resultsPerPage: number;
  resultOptions: number[];
}

export interface PaginationProps {
  paginationState: PaginationState;
  onNext: () => void;
  onPrev: () => void;
  onPageChange: (pageNumber: number) => void;
  onResultsChange: (results: number) => void;
  showRowsPerPage?: boolean;
}

export interface EllipsisComponentProps {
  key: string;
}

/**
 * Generates a paginated array with ellipsis.
 */
const paginateRender = (
  { currentPage, totalPages }: PaginationState,
  renderButton: (pageNumber: number) => unknown,
  renderEllipsis?: (props: EllipsisComponentProps) => React.ReactNode,
) => {
  // Variable delta to maintain a constant length in truncatedButtons array
  const firstDelta = currentPage - 5 < 0 ? Math.abs(currentPage - 5) : 1;
  const lastDelta =
    totalPages - currentPage - 4 < 0
      ? Math.abs(totalPages - currentPage - 4)
      : 1;
  const delta = lastDelta > 1 ? lastDelta : firstDelta;

  const left = currentPage - delta;
  const right = currentPage + delta + 1;
  const range = [];
  const truncatedButtons = [];
  let index;

  for (let i = 1; i <= totalPages; i++) {
    if (i == 1 || i == totalPages || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (const i of range) {
    if (index) {
      if (i - index === 2) {
        truncatedButtons.push(renderButton(index + 1));
      } else if (i - index !== 1) {
        truncatedButtons.push(
          renderEllipsis ? renderEllipsis({ key: `ellipsis-${i}` }) : "...",
        );
      }
    }
    truncatedButtons.push(renderButton(i));
    index = i;
  }

  return truncatedButtons;
};

export const Ellipsis = ({
  key,
}: EllipsisComponentProps): React.ReactElement => (
  <span key={key} css={css(styles.ellipsis)}>
    ...
  </span>
);

export const Pagination = ({
  paginationState,
  onNext,
  onPrev,
  onPageChange,
  onResultsChange,
  showRowsPerPage = true,
}: PaginationProps): React.ReactElement => {
  const { totalPages, currentPage, resultsPerPage, resultOptions } =
    paginationState;

  const renderButton = (pageNumber: number) => (
    <button
      role="tab"
      key={`pagination-link-${pageNumber}`}
      onClick={() => onPageChange(pageNumber)}
      data-testid={`pagination-link-${pageNumber}`}
      css={css(
        styles.pageNumber,
        currentPage === pageNumber && styles.pageNumber.active,
      )}
    >
      {pageNumber}
    </button>
  );

  return (
    <div css={css(styles.wrapper)} data-testid="pagination">
      <div role="tablist" css={css(styles.carousel)}>
        <>
          <button
            role="tab"
            onClick={() => onPageChange(1)}
            disabled={currentPage === 1}
            css={css(styles.stepButton)}
          >
            <Icon.DoubleChevron
              color="brand"
              size="small"
              css={css(
                { transform: "rotate(180deg)" },
                currentPage === 1 && {
                  color: theme.colors.greyscale.lightest,
                },
              )}
            />
          </button>
          <button
            role="tab"
            disabled={currentPage === 1}
            css={css(styles.stepButton)}
          >
            <Icon.Chevron
              onClick={onPrev}
              color="brand"
              size="small"
              css={css(
                { transform: "rotate(180deg)" },
                currentPage === 1 && {
                  color: theme.colors.greyscale.lightest,
                },
              )}
            />
          </button>
          {paginateRender(paginationState, renderButton, Ellipsis)}
          <button
            role="tab"
            disabled={currentPage === totalPages}
            css={css(styles.stepButton)}
          >
            <Icon.Chevron
              onClick={onNext}
              color="brand"
              size="small"
              css={css(
                currentPage === totalPages && {
                  color: theme.colors.greyscale.lightest,
                },
              )}
            />
          </button>
          <button
            role="tab"
            disabled={currentPage === totalPages}
            onClick={() => onPageChange(totalPages)}
            css={css(styles.stepButton)}
          >
            <Icon.DoubleChevron
              color="brand"
              size="small"
              css={css(
                currentPage === totalPages && {
                  color: theme.colors.greyscale.lightest,
                },
              )}
            />
          </button>
        </>
      </div>
      {showRowsPerPage && (
        <>
          <Text fontSize="xsmall" css={css(styles.rowPerPageWrap)}>
            Rows per page:
          </Text>
          <div css={css(styles.resultsDropdownWrap)}>
            <InputSelect
              defaultValue={resultsPerPage.toString()}
              options={[
                ...resultOptions.map((item) => {
                  return {
                    label: String(item),
                    value: String(item),
                  };
                }),
              ]}
              isSearchable={false}
              onChange={(e) => {
                onResultsChange(Number(e.target.value));
              }}
              data-testid={"pagination-results-dropdown"}
            />
          </div>
        </>
      )}
    </div>
  );
};
