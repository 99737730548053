import React, {
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { formatCurrency } from "@clearabee/ui-library-base";
import { Heading } from "../../../../Core/Heading/Heading";
import { Icon } from "../../../../Core/Icon/Icon";
import { Panel } from "../../../../Core/Panel/Panel";
import { Text } from "../../../../Core/Text/Text";
import { styles } from "./BasketPopup.styles";
import { theme } from "../../../../theme";
import { Button } from "../../../../Core/Button/Button";
import { HeaderBasketItem, HeaderContext } from "../../Header";
import { Link } from "../../../../Core/Link/Link";
import { Message } from "../../../../Core/Message/Message";

interface BasketPopupProps {
  onClose: () => void;
  overlayRef: RefObject<HTMLDivElement>;
}

interface WrapperProps {
  children: React.ReactNode;
}

interface RemoveItemsWrapProps {
  item?: HeaderBasketItem;
  resetItemToRemove: () => void;
}

type ItemToRemove =
  | {
      type: "all";
      item?: undefined;
    }
  | {
      type: "item";
      item: HeaderBasketItem;
    };

const ItemWrap = ({
  title,
  lineCost,
  link,
}: HeaderBasketItem): React.ReactElement => {
  const { LinkWrap } = useContext(HeaderContext);

  const Wrapper = !link
    ? React.Fragment
    : ({ children }: WrapperProps) => {
        return (
          <LinkWrap link={link}>
            <Link color="dark">{children}</Link>
          </LinkWrap>
        );
      };

  return (
    <Wrapper>
      <div style={styles.itemInfo}>
        <Text>{title}</Text>
        <Text styles={{ fontFamily: "ProximaBold" }} fontSize="large">
          {formatCurrency(lineCost || 0)}
        </Text>
      </div>
    </Wrapper>
  );
};

const RemoveItemsWrap = ({
  item,
  resetItemToRemove,
}: RemoveItemsWrapProps): React.ReactElement => {
  const { basket } = useContext(HeaderContext);

  if (!item) {
    return (
      <div style={styles.clearBasket}>
        <div style={styles.clearBasketContent}>
          <Text fontSize="large">
            Are you sure you want to empty your basket?
          </Text>
          <div style={styles.clearBasketButtons}>
            <Button
              color="positive"
              size="medium"
              onClick={() => {
                basket.onClearBasket();
                resetItemToRemove();
              }}
            >
              Yes
            </Button>
            <Button color="negative" size="medium" onClick={resetItemToRemove}>
              No
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={styles.removeItem}>
      <Text>
        Remove{" "}
        <Text as="span" styles={{ fontFamily: "ProximaBold" }}>
          &quot;{item?.title}&quot;
        </Text>{" "}
        ?
      </Text>
      <div style={styles.removeButtonsWrap}>
        <button
          onClick={() => basket.onRemoveBasketItem(item.sku)}
          css={[styles.removeConfirmationIcon, styles.tickIcon]}
        >
          <Icon.Tick2 size="small" color="light" />
        </button>
        <button
          css={[styles.removeConfirmationIcon, styles.crossIcon]}
          onClick={resetItemToRemove}
        >
          <Icon.Close size="small" color="light" />
        </button>
      </div>
    </div>
  );
};

export const BasketPopup = ({
  onClose,
  overlayRef,
}: BasketPopupProps): React.ReactElement => {
  const { basket, LinkWrap } = useContext(HeaderContext);
  const [itemToRemove, setItemToRemove] = useState<ItemToRemove>();
  const popupRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = ({ target }: MouseEvent) => {
    if (
      !(
        target instanceof Element &&
        (popupRef.current?.contains(target) ||
          overlayRef.current?.isSameNode(target) ||
          overlayRef.current?.childNodes?.[0].isSameNode(target))
      )
    ) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, true);

    return () =>
      document.removeEventListener("click", handleOutsideClick, true);
  }, []);

  return (
    <div css={styles.container}>
      <Panel styles={[styles.panel]} ref={popupRef}>
        <div style={styles.header}>
          <Heading level={5} color="brand">
            Your Basket
          </Heading>
          <div css={styles.closeContainer} onClick={onClose}>
            <span style={{ paddingRight: 10 }}>Close</span>
            <Icon.Close color="brand" size="small" />
          </div>
        </div>
        {basket.isUpdating && (
          <>
            <div style={styles.popupOverlay} />
            <Icon.Loading color="brand" styles={styles.loader} />
          </>
        )}
        <div css={styles.itemsContainer}>
          {basket.items
            .sort((a, b) => (a.title ?? "").localeCompare(b.title ?? ""))
            .map((item, index) => (
              <React.Fragment key={index}>
                {itemToRemove?.item?.sku !== item.sku && (
                  <div
                    css={[
                      styles.item,
                      {
                        borderTop: `${+!!index}px solid ${
                          theme.colors.greyscale.lightest
                        }`,
                      },
                    ]}
                  >
                    <div
                      css={[
                        styles.itemContainer,
                        basket.isUpdating && { opacity: 0.5 },
                      ]}
                    >
                      {item.img && (
                        <img src={item.img} style={styles.itemIcon} />
                      )}

                      <ItemWrap {...item} />
                    </div>
                    {item.isRemovable && (
                      <Link
                        onClick={() => setItemToRemove({ type: "item", item })}
                        color="negative"
                        underline
                        data-testid="remove-basket-item-link"
                      >
                        Remove
                      </Link>
                    )}
                  </div>
                )}

                {itemToRemove && itemToRemove?.item?.sku === item.sku && (
                  <RemoveItemsWrap
                    item={itemToRemove.item}
                    resetItemToRemove={() => setItemToRemove(undefined)}
                  />
                )}
              </React.Fragment>
            ))}

          {itemToRemove?.type === "all" && (
            <RemoveItemsWrap
              resetItemToRemove={() => setItemToRemove(undefined)}
            />
          )}
          {!basket.items.length && (
            <Text styles={styles.emptyBasket}>Your basket is empty</Text>
          )}
        </div>
        {basket.isError && (
          <Message
            styles={styles.basketError}
            color="light"
            type="error"
            background
          >
            There is a problem with the request, empty your basket and try again
          </Message>
        )}
        {!!basket.items.length && (
          <div css={styles.clearBasketContainer}>
            <Text as="span" fontSize="small">
              Empty Your Basket
            </Text>
            <div
              css={styles.clearBasketIcon}
              data-testid="remove-all-basket-items-link"
            >
              <Icon.Trash
                onClick={() => setItemToRemove({ type: "all" })}
                size="small"
                color="light"
                data-testid="clearIcon"
              />
            </div>
          </div>
        )}
        <Panel shadow={false} styles={styles.totalPriceContainer}>
          <Text>Total:</Text>
          <Text styles={styles.totalPrice}>
            {formatCurrency(basket.totalCost)}
          </Text>
        </Panel>
        <div
          css={[
            styles.lowerFooter,
            !basket.items.length && { justifyContent: "center" },
          ]}
        >
          <LinkWrap link="/basket">
            <Link styles={{ fontFamily: "ProximaBold" }} underline>
              View Basket
            </Link>
          </LinkWrap>

          {!!basket.items.length && (
            <LinkWrap link="/book/checkout">
              <Button
                as="a"
                size="medium"
                color="positive"
                styles={styles.button}
              >
                <Icon.PadLock size="medium" />
                Checkout Securely
              </Button>
            </LinkWrap>
          )}
        </div>
      </Panel>
    </div>
  );
};
