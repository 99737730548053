import React from "react";
import { styles } from "./Footer.styles";
import { Accreditations } from "./Components/Accreditations/Accreditations";
import { CompanyDetails } from "./Components/CompanyDetails";
import { Logo } from "./Components/Logo";
import { Icon } from "../../Core/Icon/Icon";
import { PrimaryLinks } from "./Components/PrimaryLinks/PrimaryLinks";
import { SecondaryLinks } from "./Components/SecondaryLinks";
import { SocialLinks } from "./Components/SocialLinks";
import { LinkProvider } from "./LinkContext";
import { LinkWrap } from "../../utils/types";

interface FooterProps {
  linkWrap: LinkWrap;
}

export const Footer = ({ linkWrap }: FooterProps): React.ReactElement => {
  return (
    <LinkProvider component={linkWrap}>
      <div style={styles.footer}>
        <div css={styles.container}>
          <div css={styles.topFooter}>
            <div css={styles.headingContainer}>
              <div css={styles.topLogo}>
                <Logo />
              </div>
              <div>Removing rubbish.</div>
              <div style={{ fontWeight: "bold" }}>It&apos;s what we do.</div>
              <div css={styles.topSocialLinks}>
                <SocialLinks />
              </div>
            </div>
            <div css={styles.alignRight}>
              <PrimaryLinks />
            </div>
          </div>
          <div css={styles.middleFooter}>
            <div css={styles.middleFooterContainer}>
              <div css={styles.bottomLogo}>
                <Logo />
              </div>
              <div css={styles.bottomSocialLinks}>
                <SocialLinks />
              </div>
            </div>
            <div css={styles.alignRight}>
              <Accreditations />
            </div>
          </div>

          <div css={styles.bottomFooter}>
            <div css={styles.companyDetails}>
              <CompanyDetails />
            </div>
            <div css={styles.bottomFooterExtras}>
              <SecondaryLinks />
              <div css={styles.paymentMethods}>
                <Icon.Payment width={299} height={34} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LinkProvider>
  );
};
